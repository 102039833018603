<template>
  <div class="login main" :style="$bgImg('register-bg.png')">
    <div class="toubu">
      <div class="toubu_z">“智慧转” 公共服务平台|欢迎注册</div>
      <span @click="toLogin" class="toubu_r" style="cursor:pointer"
        >已有账号？立即登录</span
      >
    </div>

    <div class="register">
      <div class="register_box">
        <div class="top_box">
          <!-- <p class="top_title">{{ is_stu == 2 ? "平台商注册" : "企业注册" }}</p> -->
          <p class="top_title">{{ "平台商/企业注册" }}</p>
          <div class="tianbao_z_top_img" @click="toLogin">
            <img class="pointer" :src="'/quxiao.png' | staticMedia" />
          </div>
        </div>
        <el-form
          :model="registerInfo"
          ref="registerForm"
          label-position="left"
          label-width="138px"
          :rules="rules"
        >
          <div class="title"><span class="title_text">企业信息</span></div>
          <el-form-item label="企业名称" prop="name">
            <el-input
              v-model="registerInfo.name"
              placeholder="请输入企业名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="统一社会信用代码" prop="jgm">
            <el-input
              v-model="registerInfo.jgm"
              placeholder="请输入统一社会信用代码"
            ></el-input>
          </el-form-item>
          <el-form-item label="法定代表人" prop="representative">
            <el-input
              v-model="registerInfo.representative"
              placeholder="请输入法定代表人名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="授权注册同意书" prop="agreebook">
            <TUpopload
              :allownum="1"
              :TU_lifils="fileList"
              @tufile-sent="handleUploadFile"
            ></TUpopload>
          </el-form-item>
          <el-form-item label="企业地点" prop="selectedOptions">
            <!-- <el-cascader
              size="large"
              :options="addressOptions"
              v-model="selectedOptions"
              @change="handleChange"
              filterable
            >
            </el-cascader> -->

            <AreaSelect @submitArea="handleGetArea"></AreaSelect>
          </el-form-item>
          <el-form-item label="详细地址" prop="addressDetail">
            <el-input
              v-model="registerInfo.addressDetail"
              placeholder="请输入详细地址"
            ></el-input>
          </el-form-item>
          <el-form-item label="成立时间" prop="date">
            <el-date-picker
              type="date"
              placeholder="选择日期"
              v-model="registerInfo.date"
              style="width: 217px;"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="企业规模" prop="enterprise_scale">
            <el-input
              v-model="registerInfo.enterprise_scale"
              placeholder="请输入企业规模"
            ></el-input>
          </el-form-item>

          <div class="title"><span class="title_text">用户信息</span></div>
          <el-form-item label="用户名" prop="username">
            <el-input
              v-model="registerInfo.username"
              placeholder="请输入用户名"
            ></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input
              type="password"
              v-model="registerInfo.password"
              placeholder="请输入密码"
            ></el-input>
          </el-form-item>
          <el-form-item label="确认密码" prop="confirmpassword">
            <el-input
              type="password"
              v-model="registerInfo.confirmpassword"
              placeholder="请输入确认密码"
            ></el-input>
          </el-form-item>
          <el-form-item label="邮箱" prop="email">
            <el-input
              v-model="registerInfo.email"
              placeholder="请输入邮箱"
            ></el-input>
          </el-form-item>
          <el-form-item label="真实姓名" prop="realname">
            <el-input
              v-model="registerInfo.realname"
              placeholder="请输入真实姓名"
            ></el-input>
          </el-form-item>
          <el-form-item label="手机号码" prop="phone">
            <el-input
              v-model="registerInfo.phone"
              placeholder="请输入手机号码"
            ></el-input>
          </el-form-item>
          <el-form-item label="验证码" prop="ssmcode">
            <el-input
              v-model="registerInfo.ssmcode"
              placeholder="请输入验证码"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <img @click="getCodeImg" :src="codeImg" alt="" />
          </el-form-item>
          <el-form-item label="" label-width="0px">
            <el-checkbox text-color="#205dc3" v-model="registerInfo.checked"
              >我同意遵守<span class="agreement" @click="openDialog"
                >《广州市“穗智转”公共服务平台用户协议》</span
              ></el-checkbox
            >
            <!-- <el-checkbox text-color="#205dc3" v-model="registerInfo.checked"
              >我同意遵守<span class="agreement" @click="openDialog"
                >《中小企业数字化转型试点中心用户协议》</span
              ></el-checkbox
            > -->
          </el-form-item>
          <el-form-item label="" style="text-align: center;width: 100%;">
            <el-button
              class="submit-btn"
              @click="submitRegister()"
              type="primary"
              >同意协议并注册</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
    <el-dialog
      title="用户协议"
      :visible.sync="dialogVisible"
      width="68%"
      top="8vh"
      :close-on-click-modal="false"
    >
      <div class="agreement-popup">
        <p>
          尊敬的用户，欢迎您注册成为“广州市“穗智转”公共服务平台”
          (下面简称“本平台”)的用户。在注册前请您仔细阅读
        </p>
        <p>如下服务协议:</p>
        <p>
          本服务协议双方为本平台与本平台用户，本服务协议具有合同效力您确认本服务协议后，本服务协议即在您和本平台之间产生法律效力。请您务必在注册之前认真阅读全部服务协议内容，如有任何疑问，可向本平台咨询。无论您事实上是否在注册之前认真阅读了本服务协议，只要您点击协议正本下方的"注册"按钮并按照本平台注册程序成功注册为用户，您的行为仍然表示您同意并签署了本服务协议
        </p>
        <p>1. 本平台服务协议的确认和接纳</p>
        <p>本平台各项服务的所有权和运作权归本平台拥有。</p>
        <p>2. 用户必须:</p>
        <p>
          (1)自行配备上网的所需设备，包括个人电脑、调制解调器或其他必备上网装置
        </p>
        <p>(2)自行负担个人上网所支付的与此服务有关的电话费用、网络费用。</p>
        <p>3. 用户在本平台上交易平台上不得发布下列违法信息:</p>
        <p>(1)反对宪法所确定的基本原则的;</p>
        <p>(2)危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的;</p>
        <p>(3)损害国家荣誉和利益的;</p>
        <p>(4)煽动民族仇恨、民族歧视，破坏民族团结的;</p>
        <p>(5)破坏国家宗教政策，宣扬邪教和封建迷信的;</p>
        <p>(6)散布谣言，扰乱社会秩序，破坏社会稳定的;</p>
        <p>(7)散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的;</p>
        <p>(8)侮辱或者诽谤他人，侵害他人合法权益的；</p>
        <p>(9)含有法律、行政法规禁止的其他内容的。</p>
        <p>4. 有关个人资料用户同意：</p>
        <p>(1)提供及时、详尽及准确的个人资料。</p>
        <p>(2)同意接收来自本平台的信息。</p>
        <p>
          (3)不断更新注册资料，符合及时、详尽准确的要求。所有原始键入的资料将引1用为注册资料。
        </p>
        <p>(4)本平台不公开用户的姓名、地址、电子邮箱和笔名，以下情况除外：</p>
        <p>(a)用户授权本平台透露这些信息</p>
        <p>
          (b)相应的法律及程序要求本平台提供用户的个人资料。如果用户提供的资包含有不正确的信息，本平台保留结束用户使用本平台信息服务资格的权利。
        </p>
        <p>
          5.
          用户在注册时应当选择稳定性及安全性相对较好的电子邮箱，并且同意接受并阅读本平台发往用户的各类电子邮件。如用户未及时从自己的电子邮箱接受电子邮件或因用户电子邮箱或用户电子邮件接收及阅读程序本身的问题使电子邮件无法正常
          接收或阅读的，只要本平台成功发送了电子邮件，应当视为用户已
          经接收到相关的电子邮件。电子邮件在发信服务器上所记录的发出时间视为送达时间。
        </p>
        <p>
          6．服务协议的修改
          本平台有权在必要时修改服务协议，本平台服务协议一旦发生变动，将会在重要页面上提示修改内容。如果不同意所改动的
          内容，用户可以主动取消获得的本平台信息服务。如果用户继续享用本平台信息服务，则视为接受服务协议的变动。本平台
          保留随时修改或中断服务而不需通知用户的权利。本平台行使修改或
          中断服务的权利，不需对用户或第三方负责。
        </p>
        <p>7. 用户隐私制度</p>
        <p>
          尊重用户个人隐私是本平台的一项基本政策。所以，本平台一定不会在未经合法用户授权时公开、编辑或透露其注册资料及保存在本平台中的非公开内容，除非有法律许可要求或本平台在诚信的基础上认为透露这些信息在以下四种情况是必要的：
        </p>
        <p>(1)遵守有关法律规定，遵从本平台合法服务程序。</p>
        <p>(2)保持维护本平台的商标所有权。</p>
        <p>(3)在紧急情况下竭力维护用户个人和社会大众的隐私安全。</p>
        <p>(4)符合其他相关的要求。本平台保留发布会员人口分析资询的权利。</p>
        <p>8.用户的帐号、密码和安全性</p>
        <p>
          你一旦注册成功成为用户，你将得到一个密码和帐号。如果你不保管好自己的帐号和密码安全，将负全部责任。另外，每个用户都要对其帐户中的所有活动和事件负全责。你可随时根据指示改变你的密码，也可以结束旧的帐户重开一个新帐户。用户同意若发现任何非法使用用户帐号或安全漏洞的情况，请立即通告本平台。
        </p>
        <p>9. 拒绝提供担保</p>
        <p>
          用户明确同意信息服务的使用由用户个人承担风险本平台不担保服务不会受中断，对服务的及时性，安全性，出错发生都不作担保，但会在能力范围内，避免出错。
        </p>
        <p>10. 有限责任</p>
        <p>
          本平台对任何直接、间接、偶然、特殊及继起的损害不负责任，这些损害来自：不正当使用本平台服务，或用户传送的信息不符合规定等。这些行为都有可能导致本平台形象受损，所以本平台事先提出这种损害的可能性，同时会尽量避免这种损害的发生。
        </p>
        <p>11. 信息的储存及限制</p>
        <p>
          本平台有判定用户的行为是否符合本平台服务协议的要求和精神的权利，如果用户违背本平台服务协议的规定，本平台有权中断其服务的帐号。
        </p>
        <p>12. 用户管理</p>
        <p>用户必须遵循：</p>
        <p>(1)使用信息服务不作非法用途。</p>
        <p>(2)不干扰或混乱网络服务。</p>
        <p>
          (3)遵守所有使用服务的网络协议、规定、程序和惯例。用户的行为准则是以因特网法规，政策、程序和惯例为根据的。
        </p>
        <p>13. 保障</p>
        <p>
          用户同意保障和维护本平台全体成员的利益，负责支付由用户使用超出服务范围引起的律师费用，违反服务协议的损害补偿费用，其它人使用用户的电脑、帐号和其它知识产权的追索费。
        </p>
        <p>14．结束服务</p>
        <p>
          用户或本平台可随时根据实际情况中断一项或多项服务。本平台不需对任何个人或第三方负责而随时中断服务。用户若反对任何服务协议的建议或对后来的条款修改有异议，或对本平台服务不满，用户可以行使如下权利：
        </p>
        <p>(1)不再使用本平台信息服务。</p>
        <p>
          (2)通知本平台停止对该用户的服务。结束用户服务后，用户使用本平台服务的权利马上中止。从那时起，用户没有权利，本平台也没有义务传送任何未处理的信息或未完成的服务给用户或第三方。
        </p>
        <p>15. 通告</p>
        <p>
          所有发给用户的通告都可通过重要页面的公告或电子邮件或常规的信件传送。服务协议的修改、服务变更、或其它重要事件的通告都会以此形式进行。
        </p>
        <p>16．信息内容的所有权</p>
        <p>
          本平台定义的信息内容包括：文字、软件、声音、相片、录象、图表；在广告中全部内容；本平台为用户提供的其它信息。所有这些内容受版权、商标、标签和其它财产所有权法律的保护。所以，用户只能在本平台和广告商授权下才能使用这些内容，而不能擅自复制、再造这些内容、或创造与内容有关的派生产品。
        </p>
        <p>17. 法律</p>
        <p>
          本平台信息服务协议要与中华人民共和国的法律解释一致。用户和本平台一致同意服从本平台所在地有管辖权的法院管辖，如发生本平台服务协议与中华人民共和国法律相抵触时，则这些条款将完全按法律规定重新解释，而其它条款则依旧保持对用户的约束力。
        </p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="dialogVisible = false"
          style="margin-right: 28px;"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { regionData, codeToText } from "element-china-area-data";
import TUpopload from "@/components/TUpupload.vue";
import configs from "@/config/index";
import { register } from "@/api/login";
import AreaSelect from "../../components/AreaSelect.vue";
export default {
  name: "Register",
  components: {
    TUpopload,
    AreaSelect,
  },
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入确认密码"));
      } else if (value !== this.registerInfo.password) {
        callback(new Error("两次输入密码不一致"));
      } else {
        callback();
      }
    };
    var validateStrPass = (rule, value, callback) => {
      const regex = /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_]+$)(?![a-z0-9]+$)(?![a-z\W_]+$)(?![0-9\W_]+$)[a-zA-Z0-9\W_]{6,30}$/;

      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (!regex.test(value)) {
          callback(
            new Error(
              "请输入大写字母、小写字母、数字、字符，包含其中三项的6-30位强密码！"
            )
          );
        } else {
          callback();
        }
      }
    };
    var validateJGM = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入统一社会信用代码"));
      } else {
        if (value.length != 18) {
          // callback(new Error("请输入正确的统一社会信用代码"));
          callback();
        } else {
          callback();
        }
      }
    };
    var validatePhone = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号码"));
      } else {
        if (value.length != 11) {
          callback(new Error("请输入正确的手机号码"));
        } else {
          callback();
        }
      }
    };
    return {
      registerInfo: {
        name: "",
        jgm: "",
        representative: "",
        province: "",
        city: "",
        area: "",
        address: "",
        date: "",
        enterprise_scale: "",
        username: "",
        password: "",
        confirmpassword: "",
        email: "",
        realname: "",
        phone: "",
        ssmcode: "",
      },
      fileList: [],
      addressOptions: regionData,
      selectedOptions: {},
      addtions: {},
      rules: {
        name: [{ required: true, message: "请输入企业名称", trigger: "blur" }],
        jgm: [
          {
            required: true,
            validator: validateJGM,
            trigger: "blur",
          },
        ],
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        password: [
          { required: true, validator: validateStrPass, trigger: "blur" },
        ],
        confirmpassword: [
          { required: true, validator: validatePass, trigger: "blur" },
        ],
        realname: [
          { required: true, message: "请输入真实姓名", trigger: "blur" },
        ],
        phone: [{ required: true, validator: validatePhone, trigger: "blur" }],
        email: [{ required: true, message: "请输入邮箱", trigger: "blur" }],
        ssmcode: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      is_stu: 0,
      dialogVisible: false,
      codeImg: "",
    };
  },
  mounted() {
    this.is_stu = this.$route.query.is_stu;
    console.log("this.is_stu", this.is_stu);
    this.getCodeImg();
    // this.getArea();
  },
  methods: {
    // // 通过接口获取地区数据
    // getArea() {
    //   getArea().then((res) => {
    //     console.log("getArea res", res);
    //   });
    // },
    handleGetArea(areaObj) {
      console.log("areaObj", areaObj);
      let _this = this;
      _this.registerInfo.province = areaObj.provinceId;
      _this.registerInfo.city = areaObj.cityId;
      _this.registerInfo.area = areaObj.areaId;
    },
    getCodeImg() {
      let random = Math.random();
      this.codeImg = `${configs.baseUrl}/index/user/getCaptcha?r=${random}&captcha_str=${configs.randomStr}`;
    },
    submitRegister() {
      let _this = this;
      if (!_this.registerInfo.checked) {
        // this.$message.error('请勾选同意遵守《广州市“穗智转”公共服务平台用户协议》')
        _this
          .$confirm(
            "是否同意遵守《广州市“穗智转”公共服务平台用户协议》？",
            "提示",
            {
              confirmButtonText: "是",
              cancelButtonText: "否",
              type: "warning",
            }
          )
          .then(() => {
            _this.registerInfo.checked = true;
          })
          .catch(() => {
            _this.$message({
              type: "error",
              message:
                "请同意遵守《广州市“穗智转”公共服务平台用户协议》后注册！",
            });
            _this.registerInfo.checked = false;
          });
      }

      this.$refs["registerForm"].validate((validate) => {
        if (validate) {
          //form表单校验通过,可以进行下一步操作
          let stu = 0;
          if (_this.is_stu == 2) {
            stu = 1;
          } else if (_this.is_stu == 3) {
            stu = 3;
          }

          let url = "";
          // console.log(_this.fileList, "_this.fileList-=-=");
          if (_this.fileList.length > 0) {
            url = _this.fileList[0];
          }

          let e_time = "";
          if (_this.registerInfo.date != "") {
            e_time = _this.registerInfo.date
              .toLocaleDateString()
              .replaceAll("/", "-");
          }

          let pdata = {
            url: "{$url|htmlentities}",
            stu: stu,
            name: _this.registerInfo.name,
            jgm: _this.registerInfo.jgm,
            representative: _this.registerInfo.representative,
            agree_file: url,
            province: _this.registerInfo.province,
            city: _this.registerInfo.city,
            area: _this.registerInfo.area,
            address: _this.registerInfo.addressDetail,
            e_time: e_time,
            enterprise_scale: _this.registerInfo.enterprise_scale,
            username: _this.registerInfo.username,
            password: _this.registerInfo.password,
            password2: _this.registerInfo.confirmpassword,
            email: _this.registerInfo.email,
            truename: _this.registerInfo.realname,
            mobile: _this.registerInfo.phone,
            captcha: _this.registerInfo.ssmcode,
            captcha_str: configs.randomStr,
          };
          // console.log("提交的数据", pdata);
          register(pdata)
            .then((res) => {
              if (res.code == 1) {
                _this.$message.success(res.msg);
                setTimeout(() => {
                  _this.$router.go(0);
                }, 1500);
              }
              //  else {
              //   _this.$message.error(res.msg);
              // }
            })
            .catch((err) => {
              console.log("error!", err);
            });
        }
      });
    },
    handleUploadFile(file) {
      this.fileList.push(file.url);
      // console.log("dd--->", file.url);
    },
    openDialog() {
      this.dialogVisible = true;
    },
    toLogin() {
      this.$router.push("/login");
    },
    handleChange(value) {
      //我们选择地址后，selectedOptions 会保存对应的区域码，例如北京的区域码为'110000'
      //我们要取出区域码对应的汉字，就需要用codeToText(区域码)进行输出
      this.addtions.selectedOptions = value;
      var name = "";
      this.selectedOptions.map((item) => (name += codeToText[item] + "/"));
      this.addtions.names = name;
      let addressList = name.split("/");
      this.registerInfo.province = addressList[0];
      this.registerInfo.city = addressList[1];
      this.registerInfo.area = addressList[2];
    },
  },
};
</script>
<style scoped lang="less">
@import url("../../assets/css/login.css");

.el-button.submit-btn {
  width: 148px;
}
::v-deep .register_box .el-form-item {
  margin-left: 103px;
  .el-form-item__content {
    width: 62%;
  }
}
::v-deep .el-dialog__body {
  height: 578px;
  overflow: auto;
}

.agreement:hover {
  text-decoration: underline;
}

.title {
  border-bottom: 1px solid #9bbef9;
  margin: 35px 0;
  position: relative;
}
.title .title_text {
  position: absolute;
  top: -20px;
  left: 90px;
  font-size: 20px;
  font-weight: 600;
  color: #205dc3;
  height: 26px;
  line-height: 26px;
  padding: 8px 12px;
  background-color: #fff;
}
</style>
